import * as React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import "../animate.css";
import axios from "axios";
import { API } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ExpectationFormContext } from "../../createContext";


const Profile = (props:any) => {
  const [state, setState] = useState({
    organisation_name: "",
    organisation_email: "",
    full_name: "",
    phone: "",
    job_role: "",
  });
  const EmployerFormContext: any = React.useContext(ExpectationFormContext);
  const {
    organisation_name,
    organisation_email,
    full_name,
    phone,
    job_role,
  }: any = state;
  const onchange = (e) => {
    setState({ 
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .get(`${API}/employer/complete-profile`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        console.log(res);
        setState({
          ...state,
          ...res.data,
        });
      })
      .catch((err) => {
        console.log(err.response);
        notify("Failed to fetch please try again");
      });
  }, []);
  const submitForm = (e) => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    e.preventDefault();
    const data = {
      full_name,
      phone,
      job_role,
    };
    axios
      .post(`${API}/employer/complete-profile`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        console.log(res);
        notify("Successful");
        //window.location.reload();
        setTimeout(() => {
          EmployerFormContext.switchActiveHomeScreen({
            ...EmployerFormContext.activeHomeScreen,
            personalityStepIsCompleted: false,
            competenceStepIsCompleted: false,
            profileStepIsCompleted: true,
            personalityExpectation: true,
            competenceExpectation: false,
            profile: false,
            finished: false,
          });
        }, 2000);
      })
      .catch((err) => {
        console.log(err.response);
        notify("Failed");
      });
  };
  const notify = (message: string) => toast(message, { containerId: "B" });
  return (
    <>
      <Col md={9} className="rightarea animated fadeInRight">
        <div className="employername">Hi {props.fullname}</div>
        <div className="empinfo">Please complete your profile</div>
        <div className="didvere"></div>
        <div className="employer_form">
          <form onSubmit={submitForm}>
            <div className="float-label">
              <label htmlFor="company_name">Organization Name</label>
              <input
                type="text"
                id="company_name"
                value={organisation_name}
                alt="organization name"
                className="organisation_name"
              />
            </div>
            <div className="float-label">
              <label htmlFor="company_email">Organization Email</label>
              <input
                type="email"
                id="company_email"
                className={"organisation_email"}
                value={organisation_email}
                alt="organization mail"
              />
            </div>
            <input
              type="text"
              id="name"
              alt="employer name"
              value={full_name}
              onChange={onchange}
              name="full_name"
              placeholder="Enter Full Name"
            />
            <input
              type="number"
              id="number"
              value={phone}
              name="phone"
              onChange={onchange}
              alt="employer number"
              placeholder="Enter Phone Number (Optional)"
            />
            <input
              type="text"
              id="role"
              value={job_role}
              name="job_role"
              onChange={onchange}
              alt="employer job role"
              placeholder="Enter your job role"
            />
            <button type="submit" onClick={submitForm} id="save" className="normalbtn">
              Save
            </button>
          </form>
        </div>
        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          toastClassName="bg-info text-white"
          hideProgressBar={true}
          position={toast.POSITION.TOP_CENTER}
        />
      </Col>
    </>
  );
};

export default Profile;
