import * as React from "react";
import "./style.css";
import precision from "../../assets/whitelogo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import social1 from "../../assets/social1.png";
import instagram from "../../assets/instagram.png";
import social3 from "../../assets/social3.png";
import Form from "react-bootstrap/Form";

interface IAppProps {}

const Footer: React.FunctionComponent<IAppProps> = (props) => {
  const [state, setFormState] = React.useState<any>({
    email: "",
    name: "",
    errorMessage: "",
    successMsg: "",
    date: new Date(),
  });
  const {
    email,
    successMsg,
    showPassword,
    errorMessage,
    isLoading,
    name,
    date,
    token,
  } = state;
  const changeActionOnFormData = (e: any) => {
    setFormState({
      ...state,
      [e.target.name]: e.target.value,
      errorMessage: "",
      successMsg: "",
    });
  };
  console.log(date.getFullYear());
  return (
    <>
      <div className="dsddds">
        <div className="Footer001">
          <div className="firstfoot">
            <div>
              <img src={precision} className="precisionlogo1" alt="precision" />
            </div>
            <div className="footertext1">
              Precision can objectively select the best candidates to shortlist
              for interviews. It shows you who is most suitable, average and
              unfit to hire at over 90% accuracy.
            </div>
          </div>
          <div className="foote11">
            <div className="foortr1">Explore</div>
            <div className="footertexta">Home</div>
            <a href="https://yudimy.com/about" className="footertexta aboutlink" target="_blank">About Us</a>
            <div className="footertexta">
              Contact Us:
              <div className="contact_details">
              <div>precision@yudimy.com</div>
              <div>+2348176100160 </div>
              </div>
              
              </div>
          </div>
          {/*
          <div className="foote11aa">
            <div className="foortr1">Legal</div>
            <div className="footertexta">Privacy</div>
            <div className="footertexta">Terms & Conditions</div>
          </div>
          <div className="foote11aa">
            <div className="foortr1">Follow</div>
            <div className="footertexta">
              <img src={social3} className="social3" alt="fb" />
              <img src={instagram} className="social3" alt="fb" />
              <img src={social1} alt="fb" />
            </div>
          </div>
          */}
          <div className="foote11aa1">
            <div className="foortr1">Connect</div>
            <div className="footertexta">Subscribe to our Newsletter</div>
            <Form className="controlform">
              <Form.Group className="bvbcm" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  className=""
                  value={name}
                  name="name"
                  onChange={changeActionOnFormData}
                  placeholder="Name"
                />
              </Form.Group>
              <Form.Group className="bvbcm" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  className=""
                  value={email}
                  name="email"
                  onChange={changeActionOnFormData}
                  placeholder="Email"
                />
              </Form.Group>
              <button className="subscribe normalbtn">Subscribe</button>
            </Form>
          </div>
        </div>
        <Row>
          <Col className="borderline1" md={12}></Col>
          <Col md={12} sm={12} xs={12} className="coppywrt">
            Copyrights {date.getFullYear()} Yudimy. All Rights Reserved
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Footer;
