import * as React from "react";
import logo from "../../assets/precision.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import trimg from "../../assets/purpletraingle.png";
import purplecircle from "../../assets/purplecircle.png";
import yellowcircle from "../../assets/yellowcirlce.png";
import blue from "../../assets/blue1.png";
import "./style.css";
import { AxiosResponse } from "axios";
import { API } from "../../config";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { Link, withRouter } from "react-router-dom";
import signin from "../../assets/signin.png";

interface IAppProps {}

const SignIn = withRouter((props): any => {
  const [state, setFormState] = React.useState<any>({
    email: "",
    showPassword: false,
    name: "",
    errorMessage: "",
    successMsg: "",
    isLoading: false,
    kigenni: true,
    password: "",
    token: "",
  });
  const {
    email,
    successMsg,
    showPassword,
    errorMessage,
    isLoading,
    name,
    password,
    token,
  } = state;
  const changeActionOnFormData = (e: any) => {
    setFormState({
      ...state,
      [e.target.name]: e.target.value,
      errorMessage: "",
      successMsg: "",
    });
  };
  const sendFormData = (e) => {
    e.preventDefault();
    setFormState({ ...state, isLoading: true });
    const data = {
      email,
      password,
    };
    axios
      .post<any, AxiosResponse<any>>(`${API}/accounts/signin`, data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setFormState({
            ...state,
            successMsg: response?.data[0]?.message,
            isLoading: false,
          });
          localStorage.setItem(
            "userToken",
            JSON.stringify(response.data.token)
          );
          props.history.push("/phase1");
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error && error.response && error.response.data) {
          return setFormState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        if (error && error.response && error.response.status === 400) {
          return setFormState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        setFormState({
          ...state,
          errorMessage: "SignIn failed",
          isLoading: false,
        });
      });
  };

  return (
    <>
      <Container fluid={true} className="contt2">
        <Row className="mkcenter">
          <Col md={6} className="side1">
            <Row className="mkcenter1">
              <Col md={10} className="mkccwn">
                <img
                  src={signin}
                  className="sidesignin"
                  alt="sideimagesignin"
                />
                <div className="doesnt">
                  Hiring doesn’t have to be that stressful Join us lets make it
                  seamless
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="signupbox" sm={8}>
            <Row className="mkcenter1">
              <Col md={10}>
                <div>
                  <Link to="/">
                    <img src={logo} className="logo" alt="logo" />{" "}
                  </Link>
                </div>
                <div className="imgtcont">
                  <img src={trimg} className="moveicons trimg" alt="trimg" />
                </div>
                <div className="signupwrapper">
                  <div className="signuptitle">Welcome Back</div>
                  <div className="circlecont">
                    <div>
                      <img
                        src={purplecircle}
                        className="purplecircle rotateroudd"
                        alt="trimg"
                      />
                    </div>
                    <img
                      src={yellowcircle}
                      className="yellowcircle rotateroudd"
                      alt="yellowcircle"
                    />
                  </div>
                  <Form onSubmit={sendFormData}>
                    {errorMessage && (
                      <Alert key={2} variant="danger">
                        {errorMessage}
                      </Alert>
                    )}
                    <Form.Group className="bvbcm" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        className="field3"
                        value={email}
                        name="email"
                        onChange={changeActionOnFormData}
                        placeholder="Email"
                      />
                    </Form.Group>
                    <Form.Group className="bvbcm" controlId="formBasicEmail">
                      <Form.Control
                        type={!showPassword ? "password" : "text"}
                        className="field3"
                        value={password}
                        name="password"
                        onChange={changeActionOnFormData}
                        placeholder="Password"
                      />
                    </Form.Group>
                    <span
                      className="eyeclod"
                      onClick={() => {
                        setFormState({
                          ...state,
                          showPassword: showPassword ? false : true,
                        });
                      }}
                    >
                      <span>&#128065;</span>
                    </span>
                    <button className="createaccount normalbtn" onSubmit={sendFormData}>
                      {!isLoading ? "Log in" : "Processing"}
                    </button>
                    <div className="signupagree">
                      <div className="imgtcont">
                        <img src={blue} className="blue rotateroudd" alt="trimg" />
                      </div>
                    </div>
                    <div>
                      <Link to="/forgotpassword" className="getpassword">
                        Forgot Password?
                      </Link>
                    </div>
                    <div className="donot">
                      <img src={trimg} className="ooss" alt="trimg" /> Do not
                      have an account?{" "}
                      <span className="precson">
                        <Link to="/signup">
                          <b>SignUp</b>
                        </Link>
                      </span>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default SignIn;
