import * as React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Navbar from "../GeneralPageComponents/Navbar";
import Footer from "../GeneralPageComponents/Footer";
import remove from "../../assets/remove.svg";
import addnew from "../../assets/addnew.svg";
import { API } from "../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

declare global {
  interface Window {
    fileInput: any;
  }
}

export default class EmailsSetup extends React.Component {
  state = {
    email: "",
    emailList: [],
    full_name: "",
    email_file: [],
    isloading: false,
    filename: "",
  };
  addNewEmail = () => {
    if (this.state.email === "") {
      return this.notify("Please enter new email address");
    }
    this.setState({
      emailList: [...this.state.emailList, this.state.email].reverse(),
    });
    this.setState({
      email: "",
    });
  };
  deleteEmail = (id) => {
    const EmailList = this.state.emailList;
    EmailList.splice(id, 1);
    this.setState({
      emailList: EmailList,
    });
  };
  onchange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  componentDidMount() {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .all([
        axios.get(`${API}/employer/progress`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/employer/complete-profile`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res, res2) => {
          console.log(res);
          console.log(res2);
          this.setState({
            ...res2.data,
          });
        })
      )
      .catch((err) => {
        console.log(err);
      });
  }
  submitForm = (e) => {
    e.preventDefault();
    const data: any = new FormData();
    // data.append("email_list", [...this.state.emailList, this.state.email]);
    data.append("email_file", this.state.email_file);
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .post(`${API}/employer/upload-emails`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        console.log(res);
        this.notify("Successfully added emails");
        this.setState({
          isloading: false,
          emailList: [],
          email_file: [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleFileChange = (e: any) => {
    this.setState({
      email_file: e.target.files[0],
      filename: e.target.files[0].name,
    });
    console.log(e.target.files);
  };
  notify = (message: string) => {
    window.scrollTo(-0, -0);
    toast(message, { containerId: "B" });
  };
  public render() {
    const { email, full_name, emailList, email_file } = this.state;
    return (
      <React.Fragment>
        <Navbar />
        <Col md={12} className="emailpage animated fadeInRight">
          <div className="employername_email">Hi {full_name}</div>
          <div className="email_descrip">
            Now let us take it from here, just provide us with their emails, and
            we will give you the right candidates for your organization
          </div>
          <div className="candidate_email">
            <form onSubmit={this.submitForm}>
              <legend>Provide Candidate's Emails</legend>
              <span>
                <input
                  type="email"
                  value={email}
                  id="email1"
                  name="email"
                  alt="candidate email"
                  onChange={this.onchange}
                  placeholder="Enter Email"
                />
              </span>
              {emailList.map((data, i) => (
                <span key={i} className="csa">
                  <input
                    type="email"
                    id="email2"
                    name="email"
                    alt="candidate email2 polish2"
                    className="pdss"
                    value={data}
                    placeholder="Enter Email"
                    disabled={true}
                    onKeyPress={(e) => {
                      console.log(e);
                      if (e.key == "Enter") {
                        this.addNewEmail();
                      }
                    }}
                  />
                  <img
                    src={remove}
                    alt="remove email"
                    className="remove"
                    onClick={this.deleteEmail}
                  />
                </span>
              ))}
              <span className="add_mail">
                <span>
                  <a className="new_mail" onClick={this.addNewEmail}>
                    <img id="add_icon" src={addnew} alt="add email" />
                    Add New{" "}
                  </a>
                </span>
                <a id="bulk_mail" onClick={() => window.fileInput?.click()}>
                  Import Bulk Email
                </a>
                <input
                  type="file"
                  onChange={this.handleFileChange}
                  style={{ display: "none" }}
                  ref={(fileInput: any) => (window.fileInput = fileInput)}
                />
              </span>
              {this.state.filename && <div>Filename: { this.state.filename}</div>}
              <button type="submit" onSubmit={this.submitForm} className="submit_mail">
                Submit
              </button>
            </form>
          </div>
          <ToastContainer
            enableMultiContainer
            containerId={"B"}
            toastClassName="bg-info text-white"
            hideProgressBar={true}
            position={toast.POSITION.TOP_CENTER}
          />
        </Col>
        <Footer />
      </React.Fragment>
    );
  }
}
