import * as React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import "../animate.css";
import axios from "axios";
import { API } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ExpectationFormContext } from "../../createContext";


const CompetenceStep = (props:any) => {
  const [state, setState] = useState({
    q1: null,
    q2: null,
    q3: null,
    q4: null,
    q5: null,
    q6: null,
    q7: null,
    q8: null,
    q9: null,
    q10: null,
    q11: null,
    q12: [],
    q13: [],
    sub: false,
  });
  const { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, sub}: any = state;
  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: parseInt(e.target.value),
    });
  };
  const onchangeCheckBox =(e)=> {
    console.log(e.target.checked);
    if((state[e.target.name].length >= 4 && e.target.checked === true && e.target.name === "q12") || (state[e.target.name].length >= 3 && e.target.checked === true && e.target.name === "q13")) {
      return;
    }
    if (e.target.checked) {
    setState({
      ...state,
      [e.target.name]: [...state[e.target.name], ...e.target.value],
    });
    }
    if (!e.target.checked) {
      const result : string[] = [];
      state[e.target.name]?.map((eachitem) => {
        console.log(eachitem);
        if (e.target.value !== eachitem) {
          result.push(eachitem);
        }
      })
      setState({
        ...state,
        [e.target.name]: [...result],
      })
    }
  };
  const EmployerFormContext: any = React.useContext(ExpectationFormContext);
  useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .get(`${API}/employer/competence-expectation`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        console.log(res);
        setState({
          ...state,
          ...res.data,
        });
      })
      .catch((err) => {
        console.log(err.response);
        notify("Failed to fetch please try again");
      });
  }, []);
  const formDecision =(e: any, pass:boolean)=> {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    e.preventDefault();
    const data = {
      q1,
      q2,
      q3,
      q4,
      q5,
      q6,
      q7,
      q8,
      q9,
      q10,
      q11,
      q12,
      q13,
      sub,
    };
    axios
      .post(`${API}/employer/competence-expectation`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        console.log(res);
        if(pass === true) {
          notify("Successful");
          setState({
            ...state,
            sub: true,
          });
          setTimeout(() => {
            EmployerFormContext.switchActiveHomeScreen({
              ...EmployerFormContext.activeHomeScreen,
             personalityStepIsCompleted: true,
             competenceStepIsCompleted: true,
             profileStepIsCompleted: true,
              personalityExpectation: false,
              competenceExpectation: false,
              finished: true,
            });
          }, 2000);
        }
        else {
          notify("Saved!");
          setState({
            ...state,
            sub: true,
          });
          setTimeout(() => {
            EmployerFormContext.switchActiveHomeScreen({
              ...EmployerFormContext.activeHomeScreen,
              personalityStepIsCompleted: true,
             competenceStepIsCompleted: true,
             profileStepIsCompleted: true,
            });
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err.response);
        notify("Failed");
        setState({
          ...state,
          sub: true,
        });
      });
  };
  const notify = (message: string) => {
    window.scrollTo(-0, -0);
    toast(message, { containerId: "B" });
  };

  return (
    <>
      <Col md={9} className="rightarea animated fadeInRight">
        <div className="employername">Hi {props.fullname}</div>
        <div className="empinfo">
          Please fill this survey, it helps us identify the performance and
          productive qualities you are looking for in this role:
        </div>
        <div className="didvere"></div>
        <div className="instructn">
          Please input your competence expectations
        </div>
        <div className="expntn12"></div>
        <div className="empinfo">Candidates Should:</div>
        <div className={
          q1 === null && sub? "questnwrap notanswered" : "questnwrap"
          }>
          <div className="questnwrap1">
            <span>1.</span>
            <span className="ques111">
              Plan, organize and ensure things are properly done.
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q1"
                  checked={q1 === 0 && true}
                  onChange={onchange}
                  value={0}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q1"
                  checked={q1 === 1 && true}
                  onChange={onchange}
                  value={1}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q1"
                  checked={q1 === 2 && true}
                  onChange={onchange}
                  value={2}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q1"
                  checked={q1 === 3 && true}
                  onChange={onchange}
                  value={3}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  checked={q1 === 4 && true}
                  name="q1"
                  onChange={onchange}
                  value={4}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q2 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>2.</span>
            <span className="ques111">
              Spot missing links from a bulk of information within a short time
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q2"
                  checked={q2 === 0 && true}
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  checked={q2 === 1 && true}
                  name="q2"
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  checked={q2 === 2 && true}
                  type="radio"
                  name="q2"
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  checked={q2 === 3 && true}
                  name="q2"
                  value="3"
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q2"
                  checked={q2 === 4 && true}
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q3 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>3.</span>
            <span className="ques111">Generate exceptional creative ideas</span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  checked={q3 === 0 && true}
                  name="q3"
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q3"
                  checked={q3 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q3"
                  checked={q3 === 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  checked={q3 === 3 && true}
                  name="q3"
                  value={3}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q3"
                  checked={q3 === 4 && true}
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q4 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>4.</span>
            <span className="ques111">
              Accommodate and manage people’s differences
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q4"
                  checked={q4 === 0 && true}
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q4"
                  checked={q4 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q4"
                  checked={q4 === 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q4"
                  checked={q4 === 3 && true}
                  value={3}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q4"
                  checked={q4 === 4 && true}
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q5 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>5.</span>
            <span className="ques111">
              Inspire themselves or others to take action
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q5"
                  checked={q5 === 0 && true}
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q5"
                  checked={q5 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q5"
                  checked={q5 === 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q5"
                  checked={q5 === 3 && true}
                  value={3}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q5"
                  checked={q5 === 4 && true}
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q6 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>6.</span>
            <span className="ques111">Be great at persuasion</span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q6"
                  checked={q6 === 0 && true}
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q6"
                  checked={q6 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q6"
                  checked={q6 === 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q6"
                  checked={q6 === 3 && true}
                  value={3}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q6"
                  checked={q6 === 4 && true}
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q7 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>7.</span>
            <span className="ques111">
              Work swiftly without compromising quality
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q7"
                  checked={q7 === 0 && true}
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q7"
                  checked={q7 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q7"
                  checked={q7 === 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q7"
                  checked={q7 === 3 && true}
                  value={3}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q7"
                  checked={q7 === 4 && true}
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q8 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>8.</span>
            <span className="ques111">
              Have the mental capacity and curiosity to learn new things quickly
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q8"
                  checked={q8 === 0 && true}
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q8"
                  checked={q8 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q8"
                  checked={q8 === 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q8"
                  checked={q8 === 3 && true}
                  value={3}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q8"
                  checked={q8 === 4 && true}
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div  className={
          q9 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>9.</span>
            <span className="ques111">
              Communicate points clearly and persuasively in speech or writing
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q9"
                  checked={q9 === 0 && true}
                  value="0"
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q9"
                  checked={q9 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q9"
                  checked={q9 === 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q9"
                  checked={q9 === 3 && true}
                  value={3}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q9"
                  checked={q9 === 4 && true}
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q10 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>10.</span>
            <span className="ques111">Rapidly use and adopt technology</span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q10"
                  checked={q10 === 0 && true}
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q10"
                  checked={q10 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q10"
                  checked={q10 === 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q10"
                  checked={q10 === 3 && true}
                  value={3}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q10"
                  checked={q10 === 4 && true}
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q11 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>11.</span>
            <span className="ques111">
              Stay competitive and ahead of the curb in delivering on the job
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q11"
                  checked={q11 === 0 && true}
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q11"
                  checked={q11 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q11"
                  checked={q11 === 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q11"
                  checked={q11 === 3 && true}
                  value={3}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q11"
                  checked={q11 === 4 && true}
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q12.length === 0 && sub? "ckkc ckkcna" : "ckkc"
          }>
          <span>12.</span>
          <span className="ques111">
            What work area does this role fall under? (pick the most relevant four)
          </span>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q12"
                checked={q12.indexOf("0") >= 0}
                value={0}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Management</div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q12"
                checked={q12.indexOf("1") >= 0}
                value={1}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Sales and Marketing </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q12"
                checked={q12.indexOf("2") >= 0}
                value={2}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Product Development and Innovation </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q12"
                checked={q12.indexOf("3") >= 0}
                value={3}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers">
              Human Capital Development and Management{" "}
            </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q12"
                checked={q12.indexOf("4") >= 0}
                value={4}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers">Operations</div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q12"
                checked={q12.indexOf("5") >= 0}
                value={5}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Market Research and Development </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q12"
                checked={q12.indexOf("6") >= 0}
                value={6}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Customer Relationship Management </div>
          </div>
        </div>

        <div className={
          q13.length === 0 && sub? "ckkc ckkcna" : "ckkc"
          }>
          <span>13.</span>
          <span className="ques111">
            The best talents that work with you (or the organization you
            represent) are those who: (pick the most crucial three)
          </span>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q13"
                checked={q13.indexOf("0") >= 0}
                value={0}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Can work independently</div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q13"
                checked={q13.indexOf("1") >= 0}
                value={1}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Can work independently and be accountable to different stakeholders </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q13"
                checked={q13.indexOf("2") >= 0}
                value={2}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Are completely passionate about their careers and making a difference with their work </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q13"
                checked={q13.indexOf("3") >= 0}
                value={3}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers">
            Are charismatic at leading or controlling people and resources{" "}
            </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q13"
                checked={q13.indexOf("4") >= 0}
                value={4}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers">Follow already laid out routine and processes</div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q13"
                checked={q13.indexOf("5") >= 0}
                value={5}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Can be ambassadors/evangelists for the organization </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q13"
                checked={q13.indexOf("6") >= 0}
                value={6}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Work to be recognized for their contribution to organizational growth
          </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q13"
                checked={q13.indexOf("7") >= 0}
                value={7}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Take ownership of their job function and professional development </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q13"
                checked={q13.indexOf("8") >= 0}
                value={8}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Are driven to multiply revenue (theirs and the organizations) </div>
          </div>
        </div>
        <div className="didvere"></div>
        <div className="btnarrea">
          <div className="saveff signoutw1 ghostbtn" onClick={(e) => formDecision(e, false)}>Save</div>
          <div className="continee signoutw1 normalbtn" onClick={(e) => formDecision(e, true)}>
            Continue
          </div>
        </div>
        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          toastClassName="bg-info text-white"
          hideProgressBar={true}
          position={toast.POSITION.TOP_CENTER}
        />
      </Col>
    </>
  );
};

export default CompetenceStep;
