import * as React from "react";
import Navbar from "../GeneralPageComponents/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.css";
import "../GeneralPageComponents/style.css";
import { useState } from "react";
import trimg from "../../assets/purpletraingle.png";
import purplecircle from "../../assets/purplecircle.png";
import blue from "../../assets/blue1.png";
import yellowcircle from "../../assets/yellowcirlce.png";
import womaninmush from "../../assets/womaninmush.png";
import partners1 from "../../assets/partners1.png";
import thumbsup from "../../assets/thumbsup.png";
import woman from "../../assets/Woman.png";
import rightsign from "../../assets/rightsign.png";
import Footer from "../GeneralPageComponents/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../config";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export interface IAppProps {}
declare global {
  interface Window {
    MonnifySDK: any;
  }
}
interface State {
  amountToPay: string;
  isLoading: boolean;
  noOfCandidates: any;
  maxCandidate: any;
  minCandidate: any;
  user: string;
  showWarning: boolean;
  isloading: boolean;
}
const PaymentCheckout = (props: any) => {
  const [state, setState] = useState<State>({
    amountToPay: "",
    isLoading: false,
    noOfCandidates: "",
    maxCandidate: "",
    minCandidate: "",
    user: "",
    showWarning: false,
    isloading: false,
  });
  const {
    amountToPay,
    user,
    noOfCandidates,
    showWarning,
    isloading,
  }: any = state;
  const handleSelect = (e) => {
    setState({
      ...state,
      amountToPay: e.target.value,
    });
  };
  const FormatAmount = (amount) => {
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const requestForPayref = () => {
    setState({
      ...state,
      isLoading: true,
    });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : props.history.push("/signin");
    axios
      .get(`${API}/employer/payment-reference`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        console.log(response);
        setState({
          ...state,
          user: response?.data?.payment_reference,
          isLoading: false,
        });
        setTimeout(() => {
          payWithMonnify(response?.data?.payment_reference);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setState({
          ...state,
          isLoading: false,
        });
      });
  };
  const CloseWarning = () => {
    setState({
      ...state,
      showWarning: false,
    });
  };
  const requestForReset = () => {
    setState({
      ...state,
      isloading: true,
    });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : props.history.push("/signin");
    axios
      .get(`${API}/employer/archive`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        console.log(response);
        setState({
          ...state,
          user: response?.data?.payment_reference,
          isLoading: false,
          isloading: false,
        });
        props.history.push("/phase1");
      })
      .catch((error) => {
        console.log(error.response);
        notify(error?.response?.data?.message);
        setState({
          ...state,
          isLoading: false,
          isloading: false,
          showWarning: false,
        });
      });
  };
  const payWithMonnify = (reference) => {
    try {
      window.MonnifySDK.initialize({
        amount: parseInt(calculateReturnAmount()),
        currency: "NGN",
        reference,
        customerFullName: user[0]?.first_name + "  " + user[0]?.last_name,
        customerEmail: "monnify@monnify.com",
        customerMobileNumber: "",
        apiKey: "MK_PROD_NNSGXTY6LF",
        contractCode: "722431733218",
        paymentDescription: "Book Session",
        isTestMode: false,
        onComplete: function (response) {
          if (response.paymentStatus == "OVERPAID") {
            notify(
              "Your current payment has exceeded the amount. The excess amount will be refunded within 24 hours"
            );
          }
          if (response.paymentStatus === "PAID") {
            // console.log(response)
            return setInterval((props.history.push = "/emails"), 9000);
          }
          if (response.paymentStatus == "PENDING") {
            notify("Payment Pending");
            return setInterval((window.location.pathname = "/"), 9000);
          }
        },
        onClose: function (data) {
          console.log(data);
        },
      });
    } catch (error) {
      console.log("Failed to initailize payment" + error);
    }
  };
  const calculateReturnAmount = (): any => {
    if (noOfCandidates == 1) {
      return noOfCandidates * 5000;
    }
    if (noOfCandidates > 1 && noOfCandidates <= 10) {
      return noOfCandidates * 3500;
    }
    if (noOfCandidates >= 11 && noOfCandidates <= 30) {
      return noOfCandidates * 896;
    }
    if (noOfCandidates >= 31 && noOfCandidates <= 50) {
      return noOfCandidates * 1280;
    }
    if (noOfCandidates >= 51 && noOfCandidates <= 100) {
      return noOfCandidates * 712;
    }
    if (noOfCandidates >= 101 && noOfCandidates <= 150) {
      return noOfCandidates * 570;
    }
    if (noOfCandidates >= 151 && noOfCandidates <= 200) {
      return noOfCandidates * 456;
    }
    if (noOfCandidates >= 201) {
      return noOfCandidates * 400;
    }
  };
  const onInputChange = (e) => {
    const letterNumber = /^[A-Za-z]+$/;
    if (e.target.value < 0) {
      return setState({
        ...state,
        noOfCandidates: 0,
      });
    }
    if (noOfCandidates >= 0) {
      return setState({
        ...state,
        noOfCandidates: e.target.value.replace(/[^0-9]+/g, ""), //only accept numbers
      });
    }
    if (noOfCandidates === "") {
      return setState({
        ...state,
        noOfCandidates: 0,
      });
    }
  };
  const notify = (message: string) => toast(message, { containerId: "B" });
  const gohome =()=>{
    window.location.assign("/");
  }
  return (
    <div>
      <Navbar />
      <Container fluid={true}>
        <Row className="firstpayment">
          <Col md={8}>
            <div className="wrappf">
              <div className="selectins slct">Select your number of candidate</div>
              <div className="selectplan1">
                <div className="slect">
                  <input
                    type="text"
                    placeholder=" Enter max number of candidate"
                    className="noofpersons form-control"
                    value={FormatAmount(noOfCandidates)}
                    onChange={onInputChange}
                  />
                </div>
                <div className="seelect">
                  Use our pricing calculator to know how much for your desired
                  number of candidate. Please select different range to see the
                  charge. Then click proceed to payment, to checkout.
                </div>
              </div>
            </div>
            <div className="wrappf">
              <div className="secpay">Amount to pay</div>
              <div className="amount22">
                <div className="amountcheck">
                  NGN {FormatAmount(calculateReturnAmount())}
                </div>
                <div>
                  {" "}
                  <button className="proceed1 normalbtn" onClick={requestForPayref}>
                    Subscribe
                  </button>
                  <button
                    className="proceed1 profgr ghostbtn"
                    onClick={() =>
                      setState({
                        ...state,
                        showWarning: true,
                      })
                    }
                  >
                    Clear Progress
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <img src={woman} className="woman" alt="woman" />
          </Col>
        </Row>
        <Row className="folzzypaypage">
          <Col md={12} className="folzzy1">
            <div className="tble1">
              <div className="bluetbl2">
                <div className="hireplace">
                  Why precision is the best for your organization
                </div>
              </div>
              <div className="bluetbl2 why">
                <div className="firsttablehead whyhead">
                  Using Yudimys’s <div className="using">Precision</div>
                </div>
                <div className="regularwhite txt">
                  <div className="secondthead">
                    <span className="timescode">
                      {" "}
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />{" "}
                    </span>
                    <div className="hundreds">
                      Save time screening candidates profile by 70%.
                    </div>
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>{" "}
                    Spend long hours Cut down recruitment time.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Categorize candidate profiles into most suitable to hire,
                    average to hire or unfit to hire.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Filters for candidates that are culture fit to work in your
                    team so you invite quality hires for interviews.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Spend quality time interviewing candidates that are the
                    right fit.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Give you further insight on candidate’s work ethic and how
                    they are likely to function when hired.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Filters for candidates that are culture fit to work in your
                    team so you invite quality hires for interviews.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Simplified talent Matching
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Yudimy’s talent profiling system shows you candidates job
                    effectiveness rating before you make that hire.
                  </div>
                </div>
              </div>
            </div>
            <div className="dls">
              <div>
                {" "}
                <button className="proceed1 backhome normalbtn" onClick={gohome}>Back to Home</button>
              </div>
            </div>
          </Col>
        </Row>
        <Footer />
      </Container>
      <Modal show={showWarning} centered={true} onHide={CloseWarning}>
        <Modal.Body>
          Please note that this action would reset the assessment
        </Modal.Body>
        <Modal.Footer>
          <Button className="btnws" variant="secondary" onClick={CloseWarning}>
            Back
          </Button>
          <Button variant="danger" className="btnws" onClick={requestForReset}>
            {isloading ? "Processing" : "Continue"}
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        toastClassName="bg-info text-white"
        hideProgressBar={true}
        position={toast.POSITION.TOP_CENTER}
      />
    </div>
  );
};

export default PaymentCheckout;
