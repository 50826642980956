import * as React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import "../animate.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IAppProps {}

const FinishedAssessment = (props: any) => {
  const [state, setState] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
    q8: "",
    q9: "",
    q10: "",
    q11: "",
    q12: "",
    q13: "",
  });
  const { q1, q2, q3, q4, q5, q6, q7, q8 } = state;
  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const notify = (message: string) => {
    window.scrollTo(-0, -0);
    toast(message, { containerId: "B" });
  };
  const logout =()=>{
    notify("Saved!");
    //localStorage.clear()
    //window.location.assign("/")
  }
  return (
    <>
      <Col md={9} className="rightarea animated fadeIn">
        <div className="employername">Hi {props.fullname}</div>

        <div className="finishemdal animated bounce">
          <div className="finisedtext">
            That was pretty Awesome! Almost there
          </div>
          <div className="fintjse">
            To finish setting up Precision Hiring you need to select your
            payment plan
          </div>
          <div className="finsihed"> Click finish to proceed to payment</div>
          <div className="btnssa">
            <div className="saveff signoutw1 ghostbtn"  onClick={logout}>Save</div>
            <div className="continee signoutw1 normalbtn">
              {" "}
              <Link to="/payment">Finish</Link>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default FinishedAssessment;
