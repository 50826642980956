import * as React from "react";
import "./style.css";
import precision from "../../assets/precision.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SideNav from "react-simple-sidenav";

interface IAppProps {}

const Navbar: React.FunctionComponent<IAppProps> = (props) => {
  const [state, setFormState] = useState({ user: false });
  const [showNav, setShowNav]: any = React.useState(false);
  useEffect(() => {
    const ls: any = localStorage.getItem("userToken");
    const isuser = JSON.parse(ls);
    setFormState({
      ...state,
      user: isuser ? true : false,
    });
  }, []);
  const signout = () => {
    localStorage.clear();
    localStorage.clear();
    window.location.assign("/");
  };

  const logOut = () => {
    localStorage.clear();
    window.location.assign("/");
  };
  return (
    <>
      <div className="Navwra11">
        <Link to="/" className="linkt2">
          <img src={precision} className="precisionlogo" alt="precision" />
        </Link>
        <div>
          <div className="addav1" onClick={() => setShowNav(true)}>
            <div className="line1"></div>
            <div className="line1 line2"></div>
            <div className="line1"></div>
          </div>
          <SideNav
            showNav={showNav}
            style={{ background: showNav ? "rgba(0, 0, 0, 0.7)" : "none" }}
            navStyle={{
              width: "70%",
              background: "#001833",
              padding: 0,
              margin: "0px",
            }}
            onHideNav={() => setShowNav(false)}
            openFromRight={true}
            titleStyle={{
              backgroundColor: "#fff",
              color: "#444444",
              paddingLeft: "1rem",
              paddingBottom: "1rem",
              paddingTop: "1rem",
              fontSize: 17,
              textAlign: "left",
            }}
            title={[
              <Link to="/" className="linkt2">
                <img
                  src={precision}
                  className="precisionlogo"
                  alt="precision"
                />
              </Link>,
            ]}
            itemStyle={{
              backgroundColor: "inherit",
              padding: 0,
              margin: "0px",
              textAlign: "left",
              paddingTop: "3rem",
            }}
            items={[
              <div className={"siddlemobile"}>
                <div className={"navitem1"}>
                  <div className={"gbshift"}>
                    <Link className={"gbn1"} to="/">
                      Home
                    </Link>
                  </div>
                  <div className="divide_thro"></div>
                  <div className={"gbshift"}>
                    {" "}
                    <Link className={"gbn1"} to="/phase1">
                      Profile
                    </Link>
                  </div>
                  {!state.user && (
                    <>
                      <div className={"gbshift"}>
                        {" "}
                        <Link className={"gbn1"} to="/phase1">
                          Login
                        </Link>
                      </div>
                      <div className={"gbshift"}>
                        {" "}
                        <Link className={"gbn1"} to="/phase1">
                          Sign Up
                        </Link>
                      </div>
                    </>
                  )}
                  {state.user && (
                    <div className={"gbshift"}>
                      {" "}
                      <span className={"gbn1"} onClick={logOut}>
                        Logout
                      </span>
                    </div>
                  )}
                </div>
              </div>,
            ]}
          />
        </div>
        <div className="addav">
          {!state.user && (
            <div className="navhome navhomebtn">
              <Link to="/"> HOME</Link>
            </div>
          )}
          {state.user && (
            <div className="navhome navhomebtn">
              <Link to="phase1">Dashboard</Link>
            </div>
          )}
          <div className="boder"></div>
          {!state.user && (
            <Link to="/signin">
              <div className="signoutw1 outt normalbtn">
                <div className="signout1">Get Started</div>
              </div>
            </Link>
          )}
          {state.user && (
            <div className="signoutw1 normalbtn" onClick={signout}>
              <div className="signout1">Sign Out</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
