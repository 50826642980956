import * as React from "react";
import logo from "../../assets/precision.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import trimg from "../../assets/purpletraingle.png";
import purplecircle from "../../assets/purplecircle.png";
import yellowcircle from "../../assets/yellowcirlce.png";
import blue from "../../assets/blue1.png";
import "./style.css";
import { AxiosResponse } from "axios";
import { API } from "../../config";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { Link, withRouter } from "react-router-dom";
import signin from "../../assets/signin.png";

interface IAppProps {}

const PasswordReset = withRouter((props): any => {
  const [state, setFormState] = React.useState<any>({
    email: "",
    errorMessage: "",
    successMsg: "",
    isLoading: false,
    password: "",
    confirm_password: "",
    token: "",
  });
  const {
    email,
    successMsg,
    errorMessage,
    isLoading,
    password,
    confirm_password,
    token,
  } = state;
  const changeActionOnFormData = (e: any) => {
    setFormState({
      ...state,
      [e.target.name]: e.target.value,
      errorMessage: "",
      successMsg: "",
    });
  };
  const validateForm = (e) => {
    e.preventDefault();
    setFormState({ ...state, isLoading: true });
    if (password.trim() === "" && confirm_password.trim() === "") {
      return setFormState({
        ...state,
        errorMessage: "please fill the empty feild(s)",
        isLoading: false,
      });
    }
    if (confirm_password !== password) {
      return setFormState({
        ...state,
        errorMessage: "Password must be the same",
        isLoading: false,
      });
    } else {
      sendFormData();
    }
  };
  const sendFormData = () => {
    const token = props.match.params.token;
    const userid = props.match.params.userid;
    setFormState({ ...state, isLoading: true });
    const data = {
      password,
      confirm_password,
    };
    axios
      .post<any, AxiosResponse<any>>(
        `${API}/accounts/reset-password/${userid}/${token}`,
        data
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setFormState({
            ...state,
            isLoading: false,
            successMsg: response?.data[0]?.message,
          });
          setTimeout(() => {
            props.history.push("/signin");
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error?.response?.data?.error) {
          return setFormState({
            ...state,
            errorMessage: error.response.data.error,
            isLoading: false,
          });
        }
        if (error && error.response && error.response.data) {
          return setFormState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        if (error && error.response && error.response.status === 400) {
          return setFormState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        setFormState({
          ...state,
          errorMessage: "Password reset failed",
          isLoading: false,
        });
      });
  };

  return (
    <>
      <Container fluid={true} className="contt2">
        <Row className="mkcenter">
          <Col md={6} className="side1">
            <Row className="mkcenter1">
              <Col md={10} className="mkccwn">
                <img
                  src={signin}
                  className="sidesignin"
                  alt="sideimagesignin"
                />
                <div className="doesnt">
                  Hiring doesn’t have to be that stressful Join us lets make it
                  seamless
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="signupbox" sm={8}>
            <Row className="mkcenter1">
              <Col md={10}>
                <div>
                  <Link to="/">
                    <img src={logo} className="logo" alt="logo" />{" "}
                  </Link>
                </div>
                <div className="imgtcont">
                  <img src={trimg} className="trimg" alt="trimg" />
                </div>
                <div className="signupwrapper">
                  <div className="signuptitle">Password Reset</div>
                  <div className="circlecont">
                    <div>
                      <img
                        src={purplecircle}
                        className="purplecircle"
                        alt="trimg"
                      />
                    </div>
                    <img
                      src={yellowcircle}
                      className="yellowcircle"
                      alt="yellowcircle"
                    />
                  </div>
                  <Form onSubmit={validateForm}>
                    {errorMessage && (
                      <Alert key={2} variant="danger">
                        {errorMessage}
                      </Alert>
                    )}
                    {successMsg && <Alert variant="info">{successMsg}</Alert>}
                    <Form.Group className="bvbcm" controlId="formBasicEmail">
                      <Form.Control
                        type="password"
                        className="field3"
                        value={password}
                        name="password"
                        onChange={changeActionOnFormData}
                        placeholder="Enter New Password"
                      />
                    </Form.Group>
                    <Form.Group className="bvbcm" controlId="formBasicEmail">
                      <Form.Control
                        type="password"
                        className="field3"
                        value={confirm_password}
                        name="confirm_password"
                        onChange={changeActionOnFormData}
                        placeholder="Confirm New Password"
                      />
                    </Form.Group>
                    <button className="createaccount normalbtn" onSubmit={validateForm}>
                      {!isLoading ? "Submit" : "Processing"}
                    </button>
                    <div className="signupagree">
                      <div className="imgtcont">
                        <img src={blue} className="blue" alt="trimg" />
                      </div>
                    </div>
                    <div className="donot">
                      <img src={trimg} className="ooss" alt="trimg" /> Do not
                      have an account?{" "}
                      <span className="precson">
                        <Link to="/signup">
                          <b>SignUp</b>
                        </Link>
                      </span>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default PasswordReset;
