import * as React from "react";
import logo from "../../assets/precision.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import trimg from "../../assets/purpletraingle.png";
import purplecircle from "../../assets/purplecircle.png";
import yellowcircle from "../../assets/yellowcirlce.png";
import blue from "../../assets/blue1.png";
import "./style.css";
import { AxiosResponse } from "axios";
import { API } from "../../config";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import signin from "../../assets/signup.png";
import { Link, withRouter } from "react-router-dom";

interface IAppProps {}

const SignUp = withRouter((props): any => {
  const [state, setFormState] = React.useState<any>({
    email: "",
    showPassword: false,
    name: "",
    errorMessage: "",
    successMsg: "",
    isLoading: false,
    kigenni: true,
    password: "",
    token: "",
  });
  const {
    email,
    successMsg,
    showPassword,
    errorMessage,
    isLoading,
    name,
    password,
    token,
  } = state;
  const changeActionOnFormData = (e: any) => {
    setFormState({
      ...state,
      [e.target.name]: e.target.value,
      errorMessage: "",
      successMsg: "",
    });
  };
  const sendFormData = (e) => {
    e.preventDefault();
    setFormState({ ...state, isLoading: true });
    const data = {
      email,
      password,
      organisation_name: name,
    };
    axios
      .post<any, AxiosResponse<any>>(`${API}/accounts/signup`, data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setFormState({
            ...state,
            successMsg: response?.data[0]?.message,
            isLoading: false,
            token: response?.data[0].token,
          });
          setTimeout(() => {
            localStorage.setItem(
              "userToken",
              JSON.stringify(response?.data[0]?.token)
            );
            props.history.push("/phase1");
          }, 1500);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error && error.response && error.response.data) {
          return setFormState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        if (error && error.response && error.response.status === 400) {
          return setFormState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        setFormState({
          ...state,
          errorMessage: "Signup failed",
          isLoading: false,
        });
      });
  };

  return (
    <>
      <Container fluid={true} className="contt1">
        <Row className="mkcenter">
          <Col md={6} className="side1">
            <Row className="mkcenter1">
              <Col md={10} className="mkccwn">
                <img
                  src={signin}
                  className="sidesignin"
                  alt="sideimagesignin"
                />
                <div className="doesnt">
                  Hiring doesn’t have to be that stressful Join us lets make it
                  seamless
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="signupbox" sm={8}>
            <Row className="mkcenter1">
              <Col md={10}>
                <div className="dssa">
                  <Link to="/">
                    <img src={logo} className="logo" alt="logo" />{" "}
                  </Link>
                  <img src={trimg} className="trimg imgtcontq" alt="trimg" />
                </div>
                {/* <div className="imgtcont">
                </div> */}
                <div className="signupwrapper">
                  <div className="signuptitle">Sign Up with Precision</div>
                  <div className="circlecont">
                    <div>
                      <img
                        src={purplecircle}
                        className="purplecircle moveicons "
                        alt="trimg"
                      />
                    </div>
                    <img
                      src={yellowcircle}
                      className="yellowcircle moveicons"
                      alt="yellowcircle"
                    />
                  </div>
                  <Form onSubmit={sendFormData}>
                    {errorMessage && (
                      <Alert key={2} variant="danger">
                        {errorMessage}
                      </Alert>
                    )}
                    {successMsg && (
                      <Alert key={2} variant="info">
                        {successMsg}
                      </Alert>
                    )}
                    <Form.Group className="bvbcm" controlId="formBasicEmail">
                      <Form.Control
                        className="field3"
                        value={name}
                        name="name"
                        onChange={changeActionOnFormData}
                        placeholder="Organization Name"
                      />
                    </Form.Group>
                    <Form.Group className="bvbcm" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        className="field3"
                        value={email}
                        name="email"
                        onChange={changeActionOnFormData}
                        placeholder="Organization Email"
                      />
                    </Form.Group>
                    <Form.Group
                      className="bvbcm negmargin"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type={!showPassword ? "password" : "text"}
                        className="field3"
                        value={password}
                        name="password"
                        onChange={changeActionOnFormData}
                        placeholder="Password"
                      />
                    </Form.Group>
                    <span
                      className="eyeclod"
                      onClick={() => {
                        setFormState({
                          ...state,
                          showPassword: showPassword ? false : true,
                        });
                      }}
                    >
                      <span className="eyees">&#128065;</span>
                    </span>
                    <button className="createaccount normalbtn" onSubmit={sendFormData}>
                      {!isLoading ? "Create Account" : "Processing"}
                    </button>
                    <div className="signupagree">
                      <img src={trimg} className="ooss rotateicons" alt="trimg" /> By
                      signing up, I agree to the{" "}
                      <span className="precson">
                        Precision Privacy Policy and Terms of Service{" "}
                      </span>
                      <div className="imgtcont">
                        <img src={blue} className="blue rotateicons" alt="trimg" />
                      </div>
                    </div>
                    <div className="have">
                      Have an account ?{" "}
                      <span className="precson">
                        <Link to="/signin">
                          <b>Login</b>
                        </Link>
                      </span>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default SignUp;
