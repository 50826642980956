import * as React from "react";
import Navbar from "../GeneralPageComponents/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.css";
import CompetenceStep from "./Competence";
import PersonalityStep from "./Personality";
import { useState } from "react";
import { ExpectationFormContext } from "../../createContext";
import FinishedAssessment from "./FInishedPage";
import Profile from "./Profile";
import { API } from "../../config";
import axios from "axios";

export interface IAppProps {}

const AssessmentPhase1 = () => {
  const [activeHomeScreen, switchActiveHomeScreen] = useState({
    profile: true,
    personalityExpectation: false,
    competenceExpectation: false,
    finished: false,
    profileStepIsCompleted: false,
    personalityStepIsCompleted: false,
    competenceStepIsCompleted: false,
    full_name: "",
    phone: "",
    job_role: "",
  });
  const onchange = (e) => {
    if (e.target.value === "0") {
      switchActiveHomeScreen({
        ...activeHomeScreen,
        profile: true,
        personalityExpectation: false,
        competenceExpectation: false,
      });
    }
    if (e.target.value === "1") {
      switchActiveHomeScreen({
        ...activeHomeScreen,
        personalityExpectation: true,
        competenceExpectation: false,
        profile: false,
      });
    }
    if (e.target.value === "2") {
      switchActiveHomeScreen({
        ...activeHomeScreen,
        personalityExpectation: false,
        competenceExpectation: true,
        profile: false,
      });
    }
  };
  const changeState = (x) => {
    if (x === "0") {
      switchActiveHomeScreen({
        ...activeHomeScreen,
        profile: true,
        personalityExpectation: false,
        competenceExpectation: false,
        finished: false,
      });
    }
    if (x === "1") {
      switchActiveHomeScreen({
        ...activeHomeScreen,
        finished: false,
        personalityExpectation: true,
        competenceExpectation: false,
        profile: false,
      });
    }
    if (x === "2") {
      switchActiveHomeScreen({
        ...activeHomeScreen,
        personalityExpectation: false,
        finished: false,
        competenceExpectation: true,
        profile: false,
      });
    }
    if (x === "3") {
      switchActiveHomeScreen({
        ...activeHomeScreen,
        personalityExpectation: false,
        competenceExpectation: false,
        finished: true,
        profile: false,
      });
    }
  };
  React.useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .all([
        axios.get(`${API}/employer/progress`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/employer/complete-profile`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res, res2) => {
          console.log(res);
          console.log(res2);
          switchActiveHomeScreen({
            ...activeHomeScreen,
            personalityStepIsCompleted: res.data.personality_expectation?true:false,
            competenceStepIsCompleted: res.data.competence_expectation?true:false,
            profileStepIsCompleted: res.data.complete_profile?true:false,
            finished:
              res.data.finish,
            ...res2.data,
          });
          // if (
          //   res?.data?.personality_expectation == true &&
          //   res?.data?.competence_expectation == true
          // ) {
          //   switchActiveHomeScreen({
          //     ...activeHomeScreen,
          //     personalityStepIsCompleted: true,
          //     competenceStepIsCompleted: true,

          //     ...res2.data,
          //   });
          // }
        })
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const {
    personalityExpectation,
    competenceExpectation,
    finished,
    profile,
    personalityStepIsCompleted,
    competenceStepIsCompleted,
    profileStepIsCompleted,
    full_name,
  } = activeHomeScreen;
  console.log(full_name);
  console.log(profileStepIsCompleted);
  console.log(competenceStepIsCompleted);
  console.log(personalityStepIsCompleted);
  return (
    <div>
      <Navbar />
      <Container fluid={true}>
        <Row>
          <Col md={3} className="leftsydbr">
            <div className="exptnfrm">
              <div className="Expectation">Profile </div>
              <div className="expntxz"></div>
              <div
                className={
                  profile ? "personality shss" : "personality shss inactivtext"
                }
                onClick={() => changeState("0")}
              >
                {" "}
                <span className="wrapradio1">
                  <label className="checkcontainer">
                    <input
                      type="checkbox"
                      name="ExpectationForm"
                      className="radio1"
                      id=""
                      value="0"
                      checked={profileStepIsCompleted}
                      onChange={onchange}
                    />
                    <span className="checkmark checksign"></span>
                  </label>
                </span>{" "}
                <span className="steptxt">
                  {" "}
                  {profileStepIsCompleted ? "Complete" : "Incomplete"} Profile
                </span>
              </div>
              <div className="Expectation">Expectation Form </div>
              <div className="expntn1"></div>
              <div
                className={
                  personalityExpectation
                    ? "personality"
                    : "personality inactivtext"
                }
                onClick={() => changeState("1")}
              >
                {" "}
                <span className="wrapradio1">
                  <label className="checkcontainer">
                    <input
                      type="checkbox"
                      name="ExpectationForm"
                      className="radio1"
                      id=""
                      value="1"
                      checked={personalityStepIsCompleted}
                      onChange={onchange}
                    />
                    <span className="checkmark checksign"></span>
                  </label>
                </span>{" "}
                <span className="steptxt"> Personality Expectation</span>
              </div>
              <div className="personality1"></div>
              <div
                className={
                  competenceExpectation
                    ? "personality"
                    : "personality inactivtext"
                }
                onClick={() => changeState("2")}
              >
                {" "}
                <span className="wrapradio1">
                  <label className="checkcontainer">
                    <input
                      type="checkbox"
                      name="ExpectationForm"
                      className="radio1"
                      id=""
                      value="2"
                      onChange={onchange}
                      checked={competenceStepIsCompleted}
                    />
                    <span className="checkmark checksign"></span>
                  </label>
                </span>
                <span className="steptxt"> Competence Expectation </span>
              </div>
              <div className="personality1"></div>
              <div
                className={finished ? "personality" : "personality inactivtext"}
                // onClick={() => changeState("3")}
              >
                {" "}
                <span className="wrapradio1">
                  <label className="checkcontainer">
                    <input
                      type="checkbox"
                      name="ExpectationForm"
                      className="radio1"
                      id=""
                      checked={finished}
                    />
                    <span className="checkmark checksign"></span>
                  </label>
                </span>{" "}
                <span className="steptxt"> Finish</span>
              </div>
            </div>
          </Col>
          <ExpectationFormContext.Provider
            value={{ activeHomeScreen, switchActiveHomeScreen }}
          >
            {profile && <Profile fullname={full_name} />}
            {personalityExpectation && <PersonalityStep fullname={full_name} />}
            {competenceExpectation && <CompetenceStep fullname={full_name} />}
            {finished && <FinishedAssessment fullname={full_name} />}
          </ExpectationFormContext.Provider>
        </Row>
      </Container>
    </div>
  );
};
export default AssessmentPhase1;
