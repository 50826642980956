import * as React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import "../animate.css";
import axios from "axios";
import { API } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import officepin from "../../assets/officepin.png";
import { ExpectationFormContext } from "../../createContext";
import { listenerCount } from "cluster";

interface IAppProps {}
declare global {
  interface Window {
    fileInput: any;
  }
}
const PersonalityStep = (props: any) => {
  const [state, setState] = useState({
    q1: null,
    q2: null,
    q3: null,
    q4: null,
    q5: [],
    q6: [],
    job_title: "",
    q5a: "",
    q5b: "",
    q6a: "",
    q6b: "",
    job_file: "",
    sub: false,
    file_name: "",
  });

  const EmployerFormContext: any = React.useContext(ExpectationFormContext);
  const {
    q1,
    q2,
    q3,
    q4,
    q5,
    q6,
    job_title,
    job_file,
    q5a,
    q5b,
    q6a,
    q6b,
    sub,
    file_name,
  }: any = state;
  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: parseInt(e.target.value),
    });
  };
  const onchangeCheckBox = (e) => {
    console.log(e.target.checked);
    if (state[e.target.name].length >= 2 && e.target.checked === true) {
      return;
    }
    if (e.target.checked) {
      setState({
        ...state,
        [e.target.name]: [...state[e.target.name], ...e.target.value],
      });
    }
    if (!e.target.checked) {
      console.log(e.target.name);
      const result : string[] = [];
      state[e.target.name].map((eachitem) => {
        console.log(eachitem);
        if (e.target.value !== eachitem) {
          result.push(eachitem);
        }
      });
      setState({
        ...state,
        [e.target.name]: [...result],
      });
    }
  };
  const formDecision = (e, pass: boolean) => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    e.preventDefault();
    if (job_title == "") {
      setState({
        ...state,
        sub: true,
      });
      return notify("Please enter job description details");
    }
    const data = {
      q1,
      q2,
      q3,
      q4,
      q5: q5,
      q6: q6,
    };
    const jobdata = new FormData();
    jobdata.append("job_title", job_title);
    jobdata.append("job_file", job_file);
    console.log(jobdata);
    console.log(data);
    axios
      .all([
        axios.post(`${API}/employer/job-description`, jobdata, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.post(`${API}/employer/personality-expectation`, data, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((firstresponse, secondresponse) => {
          console.log(firstresponse);
          console.log(secondresponse);
          if(pass === true){
          if (firstresponse?.status == 200 && secondresponse?.status == 200) {
            notify("Successful");
            setState({
              ...state,
              job_title: "",
              sub: false,
            });
            setTimeout(() => {
              EmployerFormContext.switchActiveHomeScreen({
                ...EmployerFormContext.activeHomeScreen,
                personalityStepIsCompleted: true,
                competenceStepIsCompleted: false,
                profileStepIsCompleted: true,
                personalityExpectation: false,
                competenceExpectation: true,
                finished: false,
              });
            }, 2000);
          } 
          }
          else {
            notify("Saved!");
            setState({
              ...state,
              sub: false,
            });
            setTimeout(() => {
              EmployerFormContext.switchActiveHomeScreen({
                ...EmployerFormContext.activeHomeScreen,
                personalityStepIsCompleted: true,
                competenceStepIsCompleted: false,
                profileStepIsCompleted: true,
              });
            }, 2000);
          }
        })
      )
      .catch((error) => {
        console.log(error.response);
        if (error) {
          notify("Failed to process! try again later");
          setState({
            ...state,
            sub: true,
          });
        }
      });
  };

  useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
      axios
      .all([
    axios
      .get(`${API}/employer/personality-expectation`, {
        headers: { Authorization: `Token ${token}` },
      }),
      axios.get(`${API}/employer/job-description`, {
        headers: { Authorization: `Token ${token}` },
      }),
    ])
      .then(
        axios.spread((res, res1) => {
        console.log(res);
        console.log(res1);
        setState({
          ...state,
          ...res.data,
          ...res1.data,
        });
      }))
      .catch((err) => {
        console.log(err.response);
        notify("Failed to fetch please try again");
      });
  }, []);
  const handleFileChange = (e: any) => {
    setState({
      ...state,
      job_file: e.target.files[0],
    });
    if(job_file) {
      notify("Upload successful!");
    }
    else {
      notify("Upload unsuccessful, check file format");
    }
  };
  const handleTextInput = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const notify = (message: string) => {
    window.scrollTo(-0, -0);
    toast(message, { containerId: "B" });
  };
console.log(job_file)
console.log(job_file['name'])
  return (
    <>
      <Col md={9} className="rightarea animated fadeIn">
        <div className="employername">Hi {props.fullname}</div>
        <div className="empinfo">
          Please fill this survey, it helps us identify the performance and
          productive qualities you are looking for in this role:
        </div>
        <div className="feiled">
          <div className="jobdescrip">
            Before digging into more serious stuff 😉 , why not give us a little
            detail about what you are aiming at for this role
          </div>
          <div className="digging">
            <input
              type="text"
              placeholder="Enter job title"
              name="job_title"
              value={job_title}
              onChange={handleTextInput}
              className={
                job_title === "" && sub? "digging1 form-control nofile" : "digging1 form-control"
                }
            />
            <div className="digging2">
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={(fileInput: any) => (window.fileInput = fileInput)}
                accept=".xls,.xlsx,.csv"
              />
              <button
                className="digging3 form-control"
                onClick={() => window.fileInput?.click()}
              >
                <span className="officepin1">
                <img src={officepin} className="officepin pinit" alt="officepin" />
              </span>
              {job_file? (<span className="textlimit">{job_file['name'] === undefined? file_name: job_file['name']}</span>): (<span className="attach">Attach Job Description</span>)}
              </button>
            </div>
          </div>
        </div>
        <div className="didvere"></div>
        <div className="instructn">
          Please input your personality expectations
        </div>
        <div className="expntn12"></div>
        <div className="empinfo">Candidates Should:</div>
        <div className={
          q1 === null && sub? "questnwrap notanswered" : "questnwrap"
          }>
          <div className="questnwrap1">
            <span>1.</span>
            <span className="ques111">
              Be very sociable as it’s needed to perform on the role not
              necessary
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q1"
                  checked={q1 === 0 && true}
                  onChange={onchange}
                  value={0}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q1"
                  checked={q1 === 1 && true}
                  onChange={onchange}
                  value={1}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q1"
                  checked={q1 === 2 && true}
                  onChange={onchange}
                  value={2}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q1"
                  checked={q1 === 3 && true}
                  onChange={onchange}
                  value={3}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q1"
                  checked={q1 === 4 && true}
                  onChange={onchange}
                  value={4}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q2 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>2.</span>
            <span className="ques111">
              Be more rational than creative in executing on this role
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q2"
                  checked={q2 === 0 && true}
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q2"
                  checked={q2 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q2"
                  checked={q2 === 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q2"
                  checked={q2 === 3 && true}
                  value={3}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q2"
                  checked={q2 === 4 && true}
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q3 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>3.</span>
            <span className="ques111">
              Be less intuitive and more factual to deliver on this role
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q3"
                  checked={q3 === 0 && true}
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q3"
                  checked={q3 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q3"
                  checked={q3 == 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q3"
                  value={3}
                  checked={q3 === 3 && true}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  checked={q3 === 4 && true}
                  name="q3"
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q4 === null && sub? "questnwrap notanswered serator" : "questnwrap serator"
          }>
          <div className="questnwrap1">
            <span>4.</span>
            <span className="ques111">
              Prioritize following through with a plan rather than improvising
            </span>
          </div>
          <div className="chc1">
            <div> Trivial </div>
            <div className="checkspread">
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q4"
                  checked={q4 === 0 && true}
                  value={0}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q4"
                  checked={q4 === 1 && true}
                  value={1}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  name="q4"
                  checked={q4 === 2 && true}
                  value={2}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  checked={q4 === 3 && true}
                  name="q4"
                  value={3}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="checkcontainer">
                <input
                  type="radio"
                  checked={q4 === 4 && true}
                  name="q4"
                  value={4}
                  onChange={onchange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div>Crucial</div>
          </div>
        </div>
        <div className={
          q5.length === 0 && sub? "ckkc ckkcna" : "ckkc"
          }>
          <span>5.</span>
          <span className="ques111">
            Out of the pool pick 2 crucial attributes candidates most possess in
            executing on the role and working along with the team
          </span>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                checked={q5.indexOf("0") >= 0}
                name="q5"
                value={0}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Principled</div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q5"
                checked={q5.indexOf("1") >= 0}
                value={1}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Supportive </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q5"
                checked={q5.indexOf("2") >= 0}
                value={2}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Competitive </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                checked={q5.indexOf("3") >= 0}
                name="q5"
                value={3}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers">Idealistic </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                checked={q5.indexOf("4") >= 0}
                name="q5"
                value={4}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers">Innovative</div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                checked={q5.indexOf("5") >= 0}
                name="q5"
                value={5}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Responsible </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q5"
                checked={q5.indexOf("6") >= 0}
                value={6}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Creative </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q5"
                checked={q5.indexOf("7") >= 0}
                value={7}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Assertive </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q5"
                checked={q5.indexOf("8") >= 0}
                value={8}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers"> Adaptive </div>
          </div>
        </div>
        <div className={
          q6.length === 0 && sub? "ckkc ckkcna" : "ckkc"
          }>
          <span>6.</span>
          <span className="ques111">
          Pick the work effectiveness traits that are most important for
           executing this role's primary responsibilities (select the most important 2)
          </span>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                name="q6"
                checked={q6.indexOf("0") >= 0}
                value={0}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers">
              {" "}
              Prioritizing what needs to be done and executing quickly
            </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                checked={q6.indexOf("1") >= 0}
                name="q6"
                value={1}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers">
              {" "}
              Generating innovative ideas and approaches to achieving goals{" "}
            </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                checked={q6.indexOf("2") >= 0}
                name="q6"
                value={2}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers">
              {" "}
              Creating a collaborative atmosphere for others to thrive{" "}
            </div>
          </div>
          <div className="ckkcw">
            <label className="checkcontainer">
              <input
                type="checkbox"
                checked={q6.indexOf("3") >= 0}
                name="q6"
                value={3}
                onChange={onchangeCheckBox}
              />
              <span className="checkmark"></span>
            </label>
            <div className="answers">
              Creating efficient processes that gets the job done{" "}
            </div>
          </div>
        </div>
        <div className="didvere"></div>
        <div className="btnarrea">
          <div className="saveff signoutw1 ghostbtn" onClick={(e) => formDecision(e, false)}>Save</div>
          <div className="continee signoutw1 normalbtn" onClick={(e) => formDecision(e, true)}>
            Continue
          </div>
        </div>
        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          toastClassName="bg-info text-white"
          hideProgressBar={true}
          position={toast.POSITION.TOP_CENTER}
        />
      </Col>
    </>
  );
};

export default PersonalityStep;
