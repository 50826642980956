import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import "./App.css";
import AssessmentPhase1 from "./Components/Assessments/AssessmentPhase1";
import SignUp from "./Components/AccountManager/signup";
import SignIn from "./Components/AccountManager/signin";
import Home from "./Components/Home/Home";
import PaymentCheckout from "./Components/Home/PaymentCheckout";
import EmailsSetup from "./Components/Assessments/EmailsSetup";
import ForgotPassword from './Components/AccountManager/ForgotPassword';
import PasswordReset from "./Components/AccountManager/PasswordReset";
const App: React.FC = () => {
  return (
    <div className="App">
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/phase1" component={AssessmentPhase1} />
            <Route exact path="/" component={Home} />
            <Route exact path="/emails" component={EmailsSetup} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/payment" component={PaymentCheckout} />
            <Route exact path="/signin" component={SignIn} />
            <Route
              exact
              path="/resetpassword/:userid/:token"
              component={PasswordReset}
            />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            {/* <Route
              exact
              path="/verifyemail/:userid/:token"
              component={EmailVerification}
            /> */}
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
