import * as React from "react";
import Navbar from "../GeneralPageComponents/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.css";
import "../GeneralPageComponents/style.css";
import { useState } from "react";
import trimg from "../../assets/purpletraingle.png";
import purplecircle from "../../assets/purplecircle.png";
import blue from "../../assets/blue1.png";
import yellowcircle from "../../assets/yellowcirlce.png";
import womaninmush from "../../assets/womaninmush.png";
import thumbsup from "../../assets/thumbsup.png";
import arrow from "../../assets/Arrow.png";
import rightsign from "../../assets/rightsign.png";
import Footer from "../GeneralPageComponents/Footer";
import { Link } from "react-router-dom";
import mobile_bg from "../../assets/mobile_bg.svg";
import partners1 from "../../assets/partners1.svg";
import partners2 from "../../assets/partners2.svg";
import partners3 from "../../assets/partners3.svg";
import partners4 from "../../assets/partners4.svg";
import hiring from "../../assets/hiring.svg";

export interface IAppProps {}

const Home = () => {
  return (
    <div>
      <Navbar />
      <Container fluid={true}>
        <Row className="bgrw1">
          <Col md={6} sm={12} className="firstsec">
            <div className="firstareawrap">
              <div className="selecting">
                Selecting the <span className="rytcand">Right Candidate </span>{" "}
                by going beyond polished CV’s
              </div>
              <div className="subtext">
                Use Yudimy's Precision tool to objectively select the best
                candidates to shortlist for interviews. It shows you who is most
                suitable, average and unfit to hire at over 90% accuracy.
              </div>
              <div className="requestdemo">
                <Link to="/signin">
                  <div className="signoutw1 normalbtn">
                    <div className="signout1 reqdemo">Get Started</div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="towimg"></div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="whyusewrapp">
            <Row>
              <Col md={6} sm={12}>
                <div className="whyuseLigt">
                  <span> WHY USE PRECISION</span>
                </div>
                <div className="whyuse">WHY USE PRECISION</div>
                <div className="moretext moreoften">
                  <span className="borderorange">
                    CVs and interviews can only tell you part of a prospective
                    candidate’s story and only their version of it; capabilities
                    are often exaggerated while personality traits and true
                    intentions are hidden.
                  </span>
                </div>
                <div className="moretext">
                  <span className="borderorange">
                    More often than not this is the case with many potential
                    candidates which lends credence to the logic behind Yudimy’s
                    Precision tool; to assist organisations like yours to make
                    the best talent investment every time by hiring the right
                    talent mix.
                  </span>
                </div>
              </Col>
              <Col md={6} className="text-center womaninmush1">
                <img src={womaninmush} className="womaninmush" alt="woman" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="companieswuuseprecision">
          <Col md={12} className="wg1">
            <div className="didvere"></div>
            <div className="whyuseLigt fassde1">
              <span className="fassde1"> COMPANIES WHO <span className="use_del">USE </span> <span className="precision_del">PRECISION</span></span>
            </div>
            <div className="whyuse whouse">COMPANIES WHO USE PRECISION</div>
            <div className="imglist">
              <span className="partnerlist">
              <div className="imgitem">
                <img src={partners1} className="partners" alt="partners1" />
              </div>
              <div className="imgitem">
                <img src={partners2} className="partners" alt="partners2" />
              </div>
              </span>
              <span className="partnerlist">
              <div className="imgitem">
                <img src={partners3} className="partners" alt="partners3" />
              </div>
              <div className="imgitem">
                <img src={partners4} className="partners" alt="partners4" />
              </div>
              </span>
              {/*<div className="imgitem">
                <img src={partners1} className="partners1" alt="partners1" />
                </div>*/}
            </div>
          </Col>
        </Row>
        <Row className="companieswuuseprecision1">
          <Col md={12}>
            <div className="whyuseLigt fassde">
              <span className="fassde"> HOW PRECISION <span className="works_del">WORKS ?</span></span>
            </div>
            <div className="whyuse">HOW PRECISION WORKS ?</div>
            <div className="stepsheader">
              <span><span className="orrange"> These few steps </span>will set you on
              your way to hiring the best fit for your company</span>
            </div>
            <div className="arrows">
              <img src={arrow} className="rytarrow" alt="" />
              <img src={arrow} className="rytarrow" alt="" />
            </div>
            <div className="howitwrap">
              <div className="howitimgarea">
                <img src={thumbsup} className="thumbsup" alt="thumbsup" />
                <div className="hiwork">Fill the Role Expectation Survey</div>
              </div>
              <div className="howitimgarea">
                <img src={thumbsup} className="thumbsup" alt="thumbsup" />
                <div className="hiwork">
                  We match your requirement to the applicants assessment report
                  as taken on the Pace platform to determine their level of fit
                </div>
              </div>
              <div className="howitimgarea">
                <img src={thumbsup} className="thumbsup" alt="thumbsup" />
                <div className="hiwork">
                  In 24-48 hours you get a report on the fitness of the
                  candidates in respect to the role they applied. This score
                  shows if they are suitable, average or unfit to hire.
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="folzzy">
          <Col md={12} className="folzzy1">
            <div className="tble1">
              <div className="bluetbl">
                <div className="firsttablehead">
                  Hiring the Manual and Regular way
                </div>
                <div className="regularwhite">
                  <div className="secondthead">
                    <span className="timescode">&times;</span>
                    <div className="hundreds">
                      Read hundreds of applicants profile.
                    </div>
                  </div>
                  <div className="secondthead">
                    <span className="timescode">&times;</span> Spend long hours
                    shortlisting candidates to interview.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">&times;</span>
                    Long hours interviewing multiple candidates with no
                    guarantees.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">&times;</span>
                    Relying on intuition to select or hire, and to eventually
                    find the candidate, is a misfit after months of salary
                    investment.
                  </div>
                </div>
              </div>
              <div className="bluetbl2">
                <div className="firsttablehead">
                  Using Yudimys’s <div className="using">Precision</div>
                </div>
                <div className="regularwhite">
                  <div className="secondthead">
                    <span className="timescode">
                      {" "}
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />{" "}
                    </span>
                    <div className="hundreds">
                      Save time screening candidates profile by 70%.
                    </div>
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>{" "}
                    Cut down recruitment time.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Categorize candidate profiles into most suitable to hire,
                    average to hire or unfit to hire.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Filters for candidates that are culture fit to work in your
                    team so you invite quality hires for interviews.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Spend quality time interviewing candidates that are the
                    right fit.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Give you further insight on candidate’s work ethic and how
                    they are likely to function when hired.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Filters for candidates that are culture fit to work in your
                    team so you invite quality hires for interviews.
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Simplified talent Matching
                  </div>
                  <div className="secondthead">
                    <span className="timescode">
                      <img
                        src={rightsign}
                        className="rightsign"
                        alt="rightsign"
                      />
                    </span>
                    Yudimy’s talent profiling system shows you candidates job
                    effectiveness rating before you make that hire.
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="whichdoyou">
          <Col md={6}>
            Which would you prefer Stress or Precision?
            <div className="requestdemo">
              <Link to="/signin">
                <div className="signoutw1">
                  <div className="signout1 getstarted2 normalbtn">Get Started</div>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
        <Footer />
      </Container>
    </div>
  );
};

export default Home;
